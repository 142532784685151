import history from "../history";
import api from "./apis";

import {
  ERROR_MESSAGE,
  LOADED,
  LOADEDING,
  SUCCESS_MESSAGE,
  VALIDATION_ERROR
} from "./types";



export const getStorage = () => async (dispatch) => {
  
  dispatch({type: LOADEDING});
  try {
    const response = await api(localStorage.getItem("token")).get(
      "/end-user-management/storage/all"
    );
    
    dispatch({type: LOADED});
    console.log(response.data,'response.data')
    return ( response.data );
    
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        // err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
        // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};

export const updateStorage = (data, id) => async (dispatch) => {
  
  dispatch({type: LOADEDING});
  const object = {
    uuid: id,
    name: data?.name,
    extra_use: data?.extra_use,
    extra_use_api: data?.extra_use_api,
    status: data?.status,
  };
 console.log(object)
  try {
    const response = await api(localStorage.getItem("token")).put(
      "/end-user-management/storage",
      { ...object }
    );

    if (response.status === 201) {
      
    dispatch({type: LOADED});
    dispatch({ type: SUCCESS_MESSAGE, payload: response.data.message });
    return response.status;
    }
  } catch (err) {
    /* =============Start========== */
      if(err?.response?.status === 422){
        
        Object.keys(err.response.data).forEach(function(key, index) {
          dispatch({ type: VALIDATION_ERROR, payload: [{'message': `${key} is required`,'path':key}]});
   
        });
        //err.response.data?.map(item => {
            // dispatch({ type: VALIDATION_ERROR, payload: [{'message': item.message,'path':item.path[0]}]});
       // })
      }
      if(err?.response?.status === 401 || err?.response?.status === 403 ){
          dispatch({ type: ERROR_MESSAGE, payload: err.response.data.message})
          localStorage.clear();
          history.push('/login')
      }
      dispatch({ type: ERROR_MESSAGE, payload: err.response ? err.response.data.message : history.push('/opps') });
      dispatch({type: LOADED});
    /* ===========End============*/}
};
