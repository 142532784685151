import { Steps } from "antd";
import React, { useState } from "react";
// import { SteeperWrapper } from "../../globalStyle";

const FormSteeper = ({ data, current }) => {
  const [cur, setCur] = useState(0);
  const { Step } = Steps;
  return (
    <>
      <Steps
        //   direction="vertical"
        current={current}
        size="small"
        style={{
          padding: "20px",
          position: "-webkit-sticky",
          position: "sticky",
          top: "0",
          width: "50%",
          margin: "auto",
        }}
      >
        {data.map((item) => (
          <>
            <Step
              style={{
                padding: "0px 0px 5px 0px",
                fontWeight: "600",
                color: "#02143D",
              }}
              key={item.title}
              onClick={() => {
                setCur(item.content);
              }}
              title={
                <p
                  style={{
                    color: "#02143D",
                    padding: "0px 0px 5px 0px",
                    fontSize: "14px",
                  }}
                >
                  {item.title}
                </p>
              }
            />
          </>
        ))}
      </Steps>
    </>
  );
};

export default FormSteeper;
