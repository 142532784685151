import { PlusCircleFilled } from '@ant-design/icons';
import { Breadcrumb, Button, Col, PageHeader, Row, Form, Input } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import React, { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import download from 'downloadjs';

const ImageBuild = () => {
    const [name, setName] = useState("")
    const [qualification, setQualification] = useState("")
    const [designation, setDesignation] = useState("")
    const [institution, setInstitution] = useState("")
    const [regNum, setRegNum] = useState("")
    const [schedule, setSchedule] = useState("")
    const [hospital, setHospital] = useState("")
    const ref = useRef(null)

    // const onFinish = (values) => {
    //     console.log('Success:', values);
    //   };
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };
    const onFinish = useCallback(() => {
        if (ref.current === null) {
          return
        }
    
        toPng(ref.current, { cacheBust: true, })
          .then((dataUrl) => {
            
            download(dataUrl, 'my-nodess.png');
          })
          .catch((err) => {
            console.log(err)
          })
        }, [ref])

    return ( 
        <Content style={{ margin: '0 16px' }}>
            
            <Breadcrumb style={{padding:"10px 0px"}}>
                <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
                <Breadcrumb.Item>TV Ad Management</Breadcrumb.Item>
            </Breadcrumb>

            <PageHeader
                ghost={false}
                onBack={() => window.history.back()}
                title="Image Build"
                subTitle="TV Ad Management"
                // extra={[
                // <Button key="1"  type="primary" icon={<PlusCircleFilled />} onClick = { showModal}>Create New
                // </Button>,
                // ]}
            />
                <Row gutter={16}>
                    <Col xs={24}>
                        <Form
                            name="basic"
                            labelCol={{
                            span: 8,
                            }}
                            wrapperCol={{
                            span: 16,
                            }}
                            style={{
                            maxWidth: 600,
                            }}
                            initialValues={{
                            remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Row gutter={16}>
                                <Col xs={12}>
                                    <Form.Item
                                    label="Name"
                                    name="name"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'This field is required!',
                                        },
                                    ]}
                                    >
                                    <Input onChange={(e) => setName(e.target.value)} />
                                    </Form.Item>

                                    <Form.Item
                                    label="Qualification"
                                    name="qualification"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'This field is required!',
                                        },
                                    ]}
                                    >
                                    <Input onChange={(e) => setQualification(e.target.value)} />
                                    </Form.Item>
                                    
                                    <Form.Item
                                    label="Designation"
                                    name="designation"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'This field is required!',
                                        },
                                    ]}
                                    >
                                    <Input onChange={(e) => setDesignation(e.target.value)} />
                                    </Form.Item>
                                
                                    <Form.Item
                                    label="Institution"
                                    name="institution"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'This field is required!',
                                        },
                                    ]}
                                    >
                                    <Input onChange={(e) => setInstitution(e.target.value)} />
                                    </Form.Item>
                                
                                </Col>
                                <Col xs={12}>
                                    <Form.Item
                                    label="Reg Number"
                                    name="regNum"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'This field is required!',
                                        },
                                    ]}
                                    >
                                    <Input onChange={(e) => setRegNum(e.target.value)} />
                                    </Form.Item>
                                    
                                    <Form.Item
                                    label="Hospital"
                                    name="hospital"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'This field is required!',
                                        },
                                    ]}
                                    >
                                    <Input onChange={(e) => setSchedule(e.target.value)} />
                                    </Form.Item>
                                    
                                    <Form.Item
                                    label="Schedule"
                                    name="schedule"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'This field is required!',
                                        },
                                    ]}
                                    >
                                    <Input onChange={(e) => setHospital(e.target.value)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                

                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form>
                    </Col>
                
                    <Col xs={ 24}>
                        <div>
                            <Row gutter={16} ref={ref} style={{ backgroundColor: '#FFF' }}>
                                <Col xs={7}
                                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                >
                                    <img
                                        style={{ borderRadius: '50%', alignSelf: 'center' }}
                                        src="logo.png" alt="image" />
                                </Col>
                                <Col xs={17}>
                                    <h2 style={{ fontWeight: '800', fontSize: '32px', margin: '0px', padding: '0px' }}>{name}</h2>
                                    <h2 style={{ margin: '0px', padding: '0px' }}>{qualification}</h2>
                                    <h2><br /></h2>
                                    <h2 style={{ margin: '0px', padding: '0px' }}>{designation}</h2>
                                    <h2 style={{ margin: '0px', padding: '0px' }}>{institution}</h2>
                                    <h2 style={{ margin: '0px', padding: '0px' }}>{regNum}</h2>
                                    <p></p>
                                    <h2 style={{ margin: '0px', padding: '0px' }}>{schedule}</h2>
                                    <p></p>
                                    <h2 style={{ margin: '0px', padding: '0px' }}>{hospital}</h2>
                                </Col>
                            </Row>
                        </div>
                    </Col>
            </Row>
             {/* <Button onClick={handleDownload}>Download</Button> */}
        </Content>
     );
}
 
export default ImageBuild;